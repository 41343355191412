import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import SEO from "../components/common/layout/seo"
import { Container } from "../components/global"
import TitleHeader from "../components/sections/titleHeader"
import Footer from "../components/sections/footer"

const ImprintPage = () => {
  return (
    <Layout>
      <SEO title="Terms of Service" />
      <Navigation />
      <TitleHeader>Terms of Service</TitleHeader>
      <Container>
        <h2>1. Terms</h2>
        <p>
          By accessing this Website and the App, accessible from Autote.app, you
          are agreeing to be bound by these Website Terms and Conditions of Use
          and agree that you are responsible for the agreement with any
          applicable local laws. If you disagree with any of these terms, you
          are prohibited from accessing this site. The materials contained in
          this Website are protected by copyright and trade mark law. These
          Terms of Service has been created with the help of the{" "}
          <a href="https://www.termsofservicegenerator.net">
            Terms of Service Generator
          </a>{" "}
          and the{" "}
          <a href="https://www.termsconditionsexample.com">
            Terms & Conditions Example
          </a>
          .
        </p>

        <h2>2. Use License</h2>

        <p>
          Permission is granted to temporarily download one copy of the
          materials on Autote's Website for personal, or commercial transitory
          viewing only. This is the grant of a license, not a transfer of title,
          and under this license you may not:
        </p>

        <ul>
          <li>modify or copy the materials;</li>
          <li>
            use the materials for any commercial purpose or for any public
            display;
          </li>
          <li>
            attempt to reverse engineer any software contained on Autote's
            Website;
          </li>
          <li>
            remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            transferring the materials to another person or "mirror" the
            materials on any other server.
          </li>
        </ul>

        <p>
          This will let Autote to terminate upon violations of any of these
          restrictions. Upon termination, your viewing right will also be
          terminated and you should destroy any downloaded materials in your
          possession whether it is printed or electronic format.
        </p>

        <h2>3. Disclaimer</h2>

        <p>
          All the materials on Autote’s Website are provided "as is". Autote
          makes no warranties, may it be expressed or implied, therefore negates
          all other warranties. Furthermore, Autote does not make any
          representations concerning the accuracy or reliability of the use of
          the materials on its Website or otherwise relating to such materials
          or any sites linked to this Website.
        </p>

        <h2>4. Limitations</h2>

        <p>
          Autote or its suppliers will not be hold accountable for any damages
          that will arise with the use or inability to use the materials on
          Autote’s Website, even if Autote or an authorize representative of
          this Website has been notified, orally or written, of the possibility
          of such damage. Some jurisdiction does not allow limitations on
          implied warranties or limitations of liability for incidental damages,
          these limitations may not apply to you.
        </p>

        <h2>5. Revisions and Errata</h2>

        <p>
          The materials appearing on Autote’s Website may include technical,
          typographical, or photographic errors. Autote will not promise that
          any of the materials in this Website are accurate, complete, or
          current. Autote may change the materials contained on its Website at
          any time without notice. Autote does not make any commitment to update
          the materials.
        </p>

        <h2>6. Links</h2>

        <p>
          Autote has not reviewed all of the sites linked to its Website and is
          not responsible for the contents of any such linked site. The presence
          of any link does not imply endorsement by Autote of the site. The use
          of any linked website is at the user’s own risk.
        </p>

        <h2>7. Site Terms of Use Modifications</h2>

        <p>
          Autote may revise these Terms of Use for its Website at any time
          without prior notice. By using this Website, you are agreeing to be
          bound by the current version of these Terms and Conditions of Use.
        </p>

        <h2>8. Data Protection</h2>

        <p>
          Autote shall implement and maintain reasonable procedures for
          protection personal information in compliance with applicable law and
          the Autote <Link to={"/dataSecurity"}> Privacy Policy</Link>.
        </p>

        <h2>9. Governing Law</h2>

        <p>
          Any claim related to Autote's Website or App shall be governed by the
          laws of de without regards to its conflict of law provisions.
        </p>
        <h2>10. Termination</h2>
        <p>
          You're free to stop using our Services at any time. We reserve the
          right to suspend or terminate your access to the Service with notice
          to you if:
          <ol>
            <li>You're in breach of these terms</li>
            <li>
              You're using the service in a manner that would cause a real risk
              of harm or loos to us or other users, or
            </li>
          </ol>
          We'll provide you with reasonable notice via the email address
          aassociated with your account to remedy the activiy that prompted us
          to contact you and give you the opportunity to export your stuff from
          our sesrvices. If after such notice you fail to take the steps we ask
          of you, we'll terminate or suspend your access to the service. We
          won't provide notice before termination where:
          <ol>
            <li>You're in material breach of these terms</li>
            <li>
              doing so would cause us legal liability or compromise our ability
              to provide the Services to our other users, or
            </li>
            <li>We're prohibitedd doing so by law.</li>
          </ol>
        </p>
        <h2>11. General</h2>
        <p>
          We may revise these terms from time to time. The changes will not be
          retroactive, and the most current version of the terms, which will
          always be at autote.app/tos, will govern our relationship with you. We
          will try to notify you of material revisions, for example via a
          service notification or an email to the email associated with your
          account. By continuing to access or use the Services after those
          revisions become effective, you aggree to be bound by the revised
          terms.
        </p>
        <p>
          If you have any questions regarding this agreement, you may contact
          Autote's Team
        </p>
      </Container>
      <Footer />
    </Layout>
  )
}
export default ImprintPage
