import React from "react"
import styled from "styled-components"
import { Container } from "../global"

const TitleHeader = ({ children }) => {
  return (
    <HeaderWrapper>
      <Container>
        <Header>
          <Title>{children}</Title>
        </Header>
      </Container>
    </HeaderWrapper>
  )
}

export default TitleHeader

const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.color.primary.main};
  padding: 160px 0 80px 0;
`

const Header = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.color.white.regular};
`
